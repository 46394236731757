import React from 'react';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';
import '../SCSS/Component_Work.scss';
import '../../../../Components_SCSS/Partials/Main.scss';
import {FaHouse} from "react-icons/fa6";


function Component_Work({title, description,img, galleryImages, inprogress, m2}) {


    const galleryOptions = {
        disableThumbnailScroll: true, // Wyłącz automatyczne przewijanie miniatur
        thumbnailPosition: 'bottom', // Ustawienie pozycji miniatur na dole
        imageScaleMode: 'fit', // Dostosuj skalowanie obrazków do rozmiaru galerii
        showPlayButton: false, // Wyłącz przycisk odtwarzania
        showFullscreenButton: false, // Wyłącz przycisk pełnoekranowy
        showBullets: true, // Wyłącz punkty
        showNav: true, // Wyłącz nawigację
        showThumbnails: false, // Wyłącz miniatury
        showIndex: false, // Wyłącz licznik
        autoPlay: true, // Wyłącz automatyczne odtwarzanie
        slideDuration: 450, // Ustaw czas trwania przejścia
        slideInterval: 2500, // Ustaw czas trwania slajdu
    };
    return (
        <div className="component_work container">
            <div className="component_work_content">
                <div className="component_work_content_img">
                    <img src={img} alt=""/>
                </div>
                <div className="component_work_content_text">
                    <span>{inprogress}</span>
                    <h2>{title}</h2>
                    <p>{description}</p>
                    <div className="component_work_content_text_icon">
                        <FaHouse color={'#26282b'} fontSize={'4rem'}/><p>{m2}</p>
                    </div>
                </div>
            </div>

            <div className="component_work_gallery">
                <h3>Galeria realizacji</h3>
                <ImageGallery className='galeria' items={galleryImages} {...galleryOptions} />

            </div>
        </div>


    );
}

export default Component_Work;

