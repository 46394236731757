import React, {useEffect } from 'react';
import '../../Components_SCSS/Partials/About_us.scss'
import img from '../../Assets/xda-2.webp'
import '../../Components_SCSS/Partials/Main.scss'
import {Link} from "react-router-dom";

function AboutUs() {
    useEffect(() => {
        console.log('Efekt działa!');
        window.scrollTo(0, 0);
    }, []);

    return (

        <div className="about_us container">
            <div className="about_us_text">
                <p className='about_us_title'>O Nas</p>
                <h1 className='about_us_heading'>HAL-BUD HALAMA</h1>
                <h2 className='about_us_under_heading'>Firma Budowlana oraz tartak</h2>
                <h3 className='about_us_all'>Wszystko czego potrzebujesz w jednym miejscu</h3>
                <p className='about_us_description'>Z ponad 20-letnim doświadczeniem, HAL-BUD HALAMA zaczynała od
                    imponujących budów w kluczowych
                    miastach Polski, takich jak Warszawa, Łódź, Kraków. Oferujemy profesjonalną budowę domów szkieletowych. Dzisiaj z własnym tartakiem, planujemy otworzyć sklep
                    internetowy, dostarczając wysokiej jakości materiały do Twoich budowlanych projektów.
                </p>
                <Link to={'/oferta'} className='about_us_button'>Sprawdź naszą ofertę!</Link>
            </div>
            <div className="about_us_image">
                <img src={img} alt="Logo TARTAK-HALAMA"/>
            </div>
        </div>

    );
}

export default AboutUs;