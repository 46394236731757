import '../Components_SCSS/Partials/Main.scss'
import React from 'react';
import Heading from "./Heading/Heading";
import About_us from "./Heading/About_us";
import Offer from "./Offer";
import Roadmap from "./Roadmap";
import Projects from "./Projects";
import PartnerCarousel from "./Partners";
import Footer from "./Footer";

function Main() {
    return (
        <div className="main">
            <Heading/>
            <About_us/>
            <Offer/>
            <Projects/>
            <Roadmap/>
            <PartnerCarousel/>
            <Footer />

        </div>
    );
}

export default Main;