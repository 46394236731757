import React from 'react';
import Navigation from "../../Navigation";
import Component from "../Component/Component";
import img1 from "../../../Assets/nowe/Deski.webp";

function Deski() {
    return (
        <div className="boazeria">
            <Navigation/>
            <Component
                img={img1}
                title="Deski"
                description="Oferujemy deski szalunkowe oraz do pokryć dachowych, wykonane z solidnego drewna sosnowego.
Nasze deski mają grubość 2.5 cm i dostępne są w różnych szerokościach oraz długościach, aby sprostać wymaganiom różnorodnych projektów budowlanych.
Podobnie jak w przypadku naszej więźby dachowej, również nasze deski szalunkowe są impregnowane. Ta dodatkowa ochrona pomaga zabezpieczyć drewno przed szkodliwymi czynnikami, takimi jak owady, grzyby, pleśnie, nadmierna wilgoć, promienie słoneczne oraz zmienne temperatury.
Dzięki temu, deski są bardziej trwałe i odporne, co znacząco wydłuża ich żywotność i zachowuje estetykę przez lata."
                price="Domyślny wymiar: 2.5 x ≠ różne szerokości"
                link="Kontakt"
            />
        </div>
    );
}

export default Deski;