import React from 'react';
import './Component.scss';
import '../../../Components_SCSS/Partials/Main.scss'
import { Link } from 'react-router-dom';

function Component({img, title, description, price, link}) {
    return (
        <div className="component container">
            <div className="component_left">
                <img src={img} alt=""/>
            </div>
            <div className="component_right">
                <h1>{title}</h1>
                <p>{description}</p>
                <h6>{price}</h6>
                <Link to='/kontakt'>{link}</Link>
            </div>
        </div>
    );
}

export default Component;