import React, {useEffect} from 'react';
import Navigation from "../Navigation";
import '../../Components_SCSS/Partials/Oferta.scss';
import '../../Components_SCSS/Partials/Main.scss';
import Components_Offer from "./Components_Offer";
import img1 from '../../Assets/nowe/boazeria.webp';
import img2 from '../../Assets/nowe/Deski.webp';
import img3 from '../../Assets/nowe/Krawędziaki.webp';
import img4 from '../../Assets/nowe/lata.jpg';
import img5 from '../../Assets/nowe/Polbal.webp';
import img6 from '../../Assets/nowe/Tarasowa.webp';
import img7 from '../../Assets/nowe/Tarcica.webp';
import img8 from '../../Assets/nowe/Więźba.webp';
import img9 from '../../Assets/nowe/Zrębka.webp';
import Footer from "../Footer";

function Oferta() {
    useEffect(() => {
        console.log('Efekt działa!');
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
            <Navigation/>
            <div className='oferta container'>
                <h2>Nasza oferta</h2>
                <p>Zapraszamy do zapoznania się z naszą szeroką ofertą, gdzie dbamy o każdy detal, by spełnić Twoje
                    oczekiwania. Oto kilka wyjątkowych produktów, które możemy Ci zaoferować</p>
                <div className="oferta_content_produkty container">
                    <div className="oferta_produkty">
                        <Components_Offer
                            img1={img6}
                            title1='Deska tarasowa'
                            description1='Deska tarasowa to najlepszy materiał na wykończenie tarasu, altanki czy balkonu. Wykonywana jest z drewna sosnowego, świerkowego i modrzewiowego.'
                            link1={'/oferta/deska_tarasowa'}/>
                        <Components_Offer
                            img1={img1}
                            title1='Boazeria'
                            description1='Boazerie bardzo często wykorzystuje się do podbitki dachowej oraz wykończenia ścianek drewnianych. Wykonywana jest z drewna sosnowego.'
                            link1={'/oferta/boazeria'}
                        />
                        <Components_Offer
                            img1={img5}
                            title1='Półbal'
                            description1='Deska elewacyjna, która imituje bal. Często stosowana do wykończenia elewacji zewnętrznych oraz ścian wewnętrznych, łączona na pióro i wpust.'
                            link1={'/oferta/polbal'}
                        />
                        <Components_Offer
                            img1={img8}
                            title1='Więźba dachowa'
                            description1='Więźby wykonywane są z drewna iglastego (sosna), zgodnie z wcześniej uzgodnionym projektem. Umożliwiamy impregnację zanurzeniową oraz suszenie więźby.'
                            link1={'/oferta/wiezba'}
                        />
                        <Components_Offer
                            img1={img7}
                            title1='Tarcica'
                            description1='Tarcica to produkt drzewny powstały w wyniku przetarcia drewna okrągłego na traku ramowym. Oferujemy tarcicę nieobrzynaną oraz tarcicę obrzynaną – w zależności od zamówienia. '
                            link1={'/oferta/tarcica'}
                        />
                        <Components_Offer
                            img1={img2}
                            title1='Deski'
                            description1='Doskonale sprawdzą się do wykonania szalunków. Posiadamy deski o różnych przekrojach. Najpopularniejsze wymiary grubości to 2.5 cm oraz 3.2 cm.'
                            link1={'/oferta/deski'}
                        />
                        <Components_Offer
                            img1={img4}
                            title1='Łaty i kontrłaty'
                            description1='Elementy więźby dachowej. Podane niżej wymiary są najczęściej używane przy pokryciach dachowych. Wykonane są z drewna sosnowego.'
                            link1={'/oferta/lata_i_kontrlata'}
                        />
                        <Components_Offer
                            img1={img3}
                            title1='Krawędziaki'
                            description1='Często używany jako krokwie lub murłata. Krawędziaki produkowane są z drewna sosnowego, zgodnie z zamówieniem i na życzenie klienta.'
                            link1={'/oferta/krawedziaki'}
                        />
                        <Components_Offer
                            img1={img9}
                            title1='Zrębka'
                            description1='Cząstki drewna o wymiarach od kilku milimetrów do kilku centymetrów. Powstające w wyniku rozdrabniania drewna za pomocą maszyn zrąbujących. '
                            link1={'/oferta/zrebka'}
                        />

                    </div>

                </div>
            </div>
            <Footer/>
        </>
    );
}

export default Oferta;