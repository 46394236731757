import React, {useEffect} from 'react';
import Navigation from "../Components_JSX/Navigation";
import '../Components_SCSS/Partials/stages.scss'

function Stages() {

    useEffect(() => {
        console.log('Efekt działa!');
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
            <Navigation/>

            <div className="stages">
                <div className="stages_container">
                    <h1>ETAPY ROZPOCZĘCIA BUDOWY DOMU</h1>
                    <h2>1. Przygotowanie dokumentacji przez klienta</h2>
                    <p>Przed rozpoczęciem współpracy z HAL-BUD Halama, zachęcamy klientów do zgromadzenia i złożenia
                        wymaganych dokumentów, w tym warunków zabudowy oraz pozwolenia na budowę, jeśli jest to
                        wymagane. Podkreślamy wagę tych kroków w procesie budowlanym.W tym etapie przygotowujemy również
                        szczegółowy kosztorys dla jasności finansowej, a następnie podpisujemy umowę, co stanowi
                        oficjalne rozpoczęcie współpracy.</p>
                    <h2>2. Akceptacja i planowanie</h2>
                    <p> Po otrzymaniu pozwolenia na budowę przez klienta, HAL-BUD Halama rozpoczyna proces planowania
                        budowy. W tym momencie przygotowujemy się do prac ziemnych i fundamentów, aby zapewnić solidną
                        podstawę dla nowego domu.</p>
                    <h2>3. Budowa domu przez HAL-BUD Halama</h2>
                    <p> Następnie nasz zespół profesjonalistów przystępuje do realizacji projektu. Zapewniamy efektywne
                        zarządzanie budową, gwarantując terminowość oraz najwyższą jakość wykonania.</p>
                    <h2>4. Wykonanie prac wykończeniowych</h2>
                    <p>Po zakończeniu konstrukcji budynku, przechodzimy do prac wykończeniowych. Nasze doświadczenie i
                        dbałość o detale gwarantują, że finalny efekt będzie zgodny z oczekiwaniami klienta.</p>
                    <h2>5. Finalne zakończenie i odbiór gotowego projektu</h2>
                    <p>
                        Po zakończeniu wszystkich prac, klient odbiera gotowy dom. HAL-BUD Halama zapewnia, że wszystkie
                        aspekty projektu są dokładnie sprawdzone i gotowe do zamieszkania.</p>
                </div>
            </div>
        </>
    );
}

export default Stages;