import React, {useState} from 'react';
import '../Components_SCSS/Partials/Projects.scss';
import img1 from '../Assets/home1.webp';
import img2 from '../Assets/hom2.webp';
import img3 from '../Assets/home3.webp';
import {Link} from "react-router-dom";


function Projects() {

    const [czyRozwiniety, setCzyRozwiniety] = useState(false);
    const [czyRozwiniety2, setCzyRozwiniety2] = useState(false);

    const tekst1 = `
   Dom w konstrukcji szkieletowej. Duży salon z aneksem kuchennym, kominkiem, 
   wyjściem na taras i schodami na poddasze. Pokój, łazienka, kotłownia, wiatrołap i komunikacja.
    Na poddaszu trzy pokoje, łazienka, garderoba i komunikacja. Instalacja solarna. 
    Zwarta bryła budynku i dach dwuspadowy sprzyjają szybkiej i taniej budowie i niskim kosztom eksploatacji.
  `;
    const tekst2 = `Parterowy dom jednorodzinny, zaprojektowany w technologii szkieletu drewnianego.
     Jest to bardzo wygodny , prosty i funkcjonalny dom, który swoim pełnym programem zapewnia optymalny komfort 4-osobowej rodzinie. 
     Przestronna część dzienna to duży salon, jadalnia otwierająca się na ogród poprzez ogromne przeszklenie, kuchnia ze spiżarnią oraz wc. Atutem projektu jest zadaszony taras.`;

    const skrocTekst = () => {
        setCzyRozwiniety(!czyRozwiniety);
    };
    const skrocTekst2 = () => {
        setCzyRozwiniety2(!czyRozwiniety2);
    }

    return (
        <div className="projects">
            <div className="projects__container container">
                <div className="projects__container__title">
                    <h2>Przykładowe Projekty</h2>
                </div>
                <div className="projects__container__content">
                    <div className="projects__container__content__item">
                        <div className="projects__container__content__item__img">
                            <Link target={"_blank"}
                                  to="https://www.extradom.pl/projekt-domu-nasza-stodola-wersja-szkieletowa-KRY1006">

                                <img src={img1} alt="project"/>
                            </Link>
                        </div>
                        <div className="projects__container__content__item__text">
                            <h3>Stodoła wersja szkieletowa KRY1006
                            </h3>
                            <p>
                                Projekt domu z poddaszem z obszernym garażem, dachem dwuspadowym oraz tarasem.

                            </p>
                        </div>
                    </div>

                    <div className="projects__container__content__item">
                        <div className="projects__container__content__item__img">
                            <Link target={'_blank'}
                                  to="https://www.extradom.pl/projekt-domu-hoczew-srednia-21-dws-KRP2269">

                                <img src={img2} alt="project"/>
                            </Link>
                        </div>
                        <div className="projects__container__content__item__text">
                            <h3>Hoczew średnia 21 dws KRP2269
                            </h3>
                            <p>{czyRozwiniety ? tekst1 : `${tekst1.slice(0, 100)}...`}</p>
                            <button className='projects__container__content__item_button' onClick={skrocTekst}>
                                {czyRozwiniety ? 'Czytaj mniej' : 'Czytaj więcej...'}
                            </button>
                        </div>
                    </div>

                    <div className="projects__container__content__item">

                        <div className="projects__container__content__item__img">
                            <Link target={'_blank'} to="https://www.extradom.pl/projekt-domu-endo-drewniany-BSE1076">
                                <img className='img_small' src={img3} alt="project"/>
                            </Link>

                        </div>


                        <div className="projects__container__content__item__text">
                            <h3>Endo drewniany BSE1076
                            </h3>
                            <p>{czyRozwiniety2 ? tekst2 : `${tekst2.slice(0, 100)}...`}</p>
                            <button className='projects__container__content__item_button' onClick={skrocTekst2}>
                                {czyRozwiniety2 ? 'Czytaj mniej' : 'Czytaj więcej...'}

                            </button>
                        </div>

                    </div>


                </div>
                <Link to="/projekty" className='projects__container__content_more'>Zobacz więcej projektów!</Link>

            </div>
        </div>
    );
}

export default Projects;