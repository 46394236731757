import React from 'react';
import Navigation from "../../Navigation";
import Component from "../Component/Component";
import img1 from "../../../Assets/nowe/20240125_161000.webp";

function DeskaTarasowa() {
    return (<div className="deska_tarasowa">
        <Navigation/>
        <Component
            img={img1}
            title="Deska tarasowa"
            description="Odkryj idealne rozwiązanie dla Twojego tarasu z naszą wysokiej jakości deską tarasową,
 dostępną w trzech różnych rodzajach drewna: sosnowym, świerkowym i modrzewiowym.
Nasze deski tarasowe są specjalnie zaprojektowane,
by sprostać Twoim potrzebom, oferując trwałość i estetykę w standardowym wymiarze 2.8 x 14 cm.
Nasze deski tarasowe posiadają ryflowaną powierzchnię, która zapewnia znakomitą przyczepność, nawet w mokrych warunkach.
 Ryflowanie ułatwia odpływ wody, co jest kluczowe dla utrzymania tarasu w doskonałym stanie przez wiele lat.
 Ponadto, technika ryflowania minimalizuje ryzyko spękań powierzchniowych, zapewniając długotrwały i estetyczny wygląd tarasu."
            price="Domyślny wymiar: 2.8 x 14"
            link="Kontakt"
        />
    </div>);
}

export default DeskaTarasowa;