import React, {useEffect} from 'react';
import '../Components_SCSS/Partials/Offer.scss'
import img1 from '../Assets/img1.webp'
import img2 from '../Assets/img2.webp'
import img3 from '../Assets/shop.webp'
import '../Components_SCSS/Partials/Main.scss'
import {Link} from "react-router-dom";

function Offer() {
       useEffect(() => {
            console.log('Efekt działa!');
            window.scrollTo(0, 0);
        }, []);

    return (
        <div className='offer container'>
            <div className='offer_heading_box'>
                <h2 className='offer_heading_text'>Nasza oferta</h2>
                <p className='offer_heading_description'>

                    Oferujemy kompleksowe rozwiązania w trzech obszarach działalności. Nasze domy całoroczne to solidne
                    konstrukcje, zapewniające komfortowe warunki mieszkalne. W dziedzinie wyrobów stolarskich i
                    tartacznych dostarczamy wysokiej jakości produkty.
                </p>
            </div>
            <div className="heading_content">
                <Link to={'/projekty'}>
                    <div className='heading_content_box'>
                        <img src={img1} alt=""/>
                        <h1>Domy całoroczne</h1>
                    </div>
                </Link>
                <Link to={"/oferta"}>
                    <div className='heading_content_box'>
                        <img src={img2} alt=""/>
                        <h1>Wyroby stolarskie oraz tartaczne</h1>
                    </div>
                </Link>
                <Link to={'https://koszykstolarza.pl'}>
                    <div className='heading_content_box'>
                        <img src={img3} className='shop_img' alt=""/>
                        <h1>Sklep internetowy</h1>
                    </div>
                </Link>
            </div>
        </div>
    );
}

export default Offer;