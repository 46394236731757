import React, {useEffect} from 'react';
import Navigation from "../../../Navigation";
import Component_Work from "./Component_Work";
import '../SCSS/First_Work.scss';
import img1 from '../../../../Assets/l39/1 (1).webp';
import img2 from '../../../../Assets/l39/1 (2).webp';
import img3 from '../../../../Assets/l39/1 (3).webp';
import img4 from '../../../../Assets/l39/1 (4).webp';
import img5 from '../../../../Assets/l39/1 (5).webp';
import img6 from '../../../../Assets/l39/1 (6).webp';
import img7 from '../../../../Assets/l39/1 (7).webp';


const imagesSet1 = [
    {
        original: img1,
        originalAlt: 'Dom szkieletowy nowoczesny',
    }
    ,
    {
        original: img2,
        originalAlt: 'Budowa szkieletowa drewniana',
    }
    ,
    {
        original: img3,
        originalAlt: 'Projekt domu szkieletowego',
    }
    ,
    {
        original: img4,
        originalAlt: 'Drewniana konstrukcja domu',
    }
    ,
    {
        original: img5,
        originalAlt: 'Konstrukcja dachu szkieletowego',
    }
    ,
    {
        original: img6,
        originalAlt: 'Dach szkieletowy drewniany',
    }
    ,
    {
        original: img7,
        originalAlt: 'Szkieletowy dom stylowy',
    }
];

function SecondWork() {
    useEffect(() => {
        console.log('Efekt działa!');
        window.scrollTo(0, 0);
    }, []);
    return (

        <div className="firstwork">
            <Navigation/>
            <Component_Work
                img={img3}
                title='Projekt domu L-39'
                description='Projekt "L-39" to idealne rozwiązanie dla rodzin pragnących komfortowego domu na działce średniej wielkości. Charakteryzuje się prostą, elegancką bryłą i dachem dwuspadowym, co ułatwia budowę i adaptację do różnorodnych środowisk. Wnętrze, zaprojektowane z myślą o 4-osobowej rodzinie, oferuje przestronną część dzienną i funkcjonalną kuchnię, dostosowaną do indywidualnych potrzeb. Dom zawiera również praktyczne rozwiązania sanitarne oraz sypialnie gwarantujące prywatność i komfort, z opcją adaptacji na pokój gościnny czy gabinet. Dodatkowym atutem jest weranda, zwiększająca urok i oferująca miejsce do relaksu na świeżym powietrzu. Projekt "L-39" łączy wygodę, styl i ekonomiczne rozwiązania, będąc doskonałym wyborem w nowoczesnym budownictwie mieszkaniowym.'
                galleryImages={imagesSet1}
                m2='72.60 m2'


            />
        </div>

    );
}

export default SecondWork;