import Navigation from "../Navigation";
import './Project.scss'
import React, {useEffect, useState} from 'react';
import Footer from "../Footer";

function Project() {

    const [isComponentMounted, setComponentMounted] = useState(false);

    useEffect(() => {
        setComponentMounted(true);

        // Symulacja opóźnienia na potrzeby przykładu
        const timeoutId = setTimeout(() => {
            if (isComponentMounted) {
                console.log('Efekt działa!');
                window.scrollTo(0, 0);
            }
        }, 1);

        // Czyszczenie timeout'u przy odmontowywaniu komponentu
        return () => clearTimeout(timeoutId);
    }, [isComponentMounted]);


    useEffect(() => {
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = 'https://static.extradom.pl/js/partner/iframeResizer.min.js';
        document.head.appendChild(script);

        script.onload = () => {
            window.iFrameResize({
                enablePublicMethods: false,
                enableInPageLinks: true,
                checkOrigin: true,
                heightCalculationMethod: 'grow',
                autoResize: true,

            });
        };

        return () => {
            document.head.removeChild(script);
        };
    }, []);

    return (
        <>
            <Navigation/>

            <div className="projekty">
                <div className="project">
                    <h2>Oszczędzaj na swoim marzeniu o wymarzonym domu!</h2>
                    <p>Wybierz naszą firmę do zakupu projektu domu i ciesz się rabatem do 10%! Zrealizuj swoje
                        marzenia o nowoczesnym i funkcjonalnym domu bez przekraczania budżetu.</p>
                    <iframe
                        className='extradom'
                        src="https://embed.extradom.pl/?partnerId=005Qt000003rj0WIAQ"
                        width="100%"
                        height="auto"
                        frameBorder="0"
                        scrolling="no">

                    </iframe>
                </div>
                <Footer />

            </div>

        </>
    );
}

export default Project;