import React, {useEffect} from 'react';
import Navigation from "../../../Navigation";
import '../SCSS/First_Work.scss';
import Component_Work from "./Component_Work";
import img1 from '../../../../Assets/radosny/1 (1).webp'
import img2 from '../../../../Assets/radosny/1 (2).webp'
import img3 from '../../../../Assets/radosny/1 (3).webp'
import img4 from '../../../../Assets/radosny/1 (4).webp'
import img5 from '../../../../Assets/radosny/1 (5).webp'
import img6 from '../../../../Assets/radosny/1 (6).webp'
import img7 from '../../../../Assets/radosny/1 (7).webp'
import img8 from '../../../../Assets/radosny/1 (8).webp'
import img9 from '../../../../Assets/radosny/1 (9).webp'
import img10 from '../../../../Assets/radosny/1 (10).webp'



const imagesSet1 = [

    {
        original: img2,
        originalAlt: 'Radosny 4 widok zewnętrzny',
    },

    {
        original: img3,
        originalAlt: 'Nowoczesny salon z jadalnią',
    },
    {
        original: img4,
        originalAlt: 'Zastosowanie blachy dachowej',
    },
    {
        original: img5,
        originalAlt: 'Dom parterowy nowoczesny',
    },
    {
        original: img6,
        originalAlt: 'Salon z kominkiem',
    },
    {
        original: img7,
        originalAlt: 'Projekt domu z garażem',
    },
    {
        original: img1,
        originalAlt: 'Elegancki wiatrołap wejściowy',
    },
    {
        original: img8,
        originalAlt: 'Drewniana elewacja domu',
    },
    {
        original: img9,
        originalAlt: 'Dom z ekologicznymi rozwiązaniami',
    },
    {
        original: img10,
        originalAlt: 'Przestronny salon z jadalnią',
    },

];

function FourWork() {
    useEffect(() => {
        console.log('Efekt działa!');
        window.scrollTo(0, 0);
    }, []);

    return (

        <div className="firstwork">
            <Navigation/>
            {/* eslint-disable-next-line react/jsx-pascal-case */}
            <Component_Work
                img={img9}
                title='Radosny 2 WAW1141'
                description='Projekt ten cechuje się ponadczasową estetyką, oferując elegancki i nowoczesny wygląd. Jego niewielkie wymiary zewnętrzne umożliwiają lokalizację na działce w różnych układach, zarówno pionowym, jak i poziomym, co jest idealne dla różnych typów terenów. Jest to przemyślany dom, który zapewnia optymalną powierzchnię użytkową dla 2-3-osobowej rodziny, z wyraźnie oddzielonymi strefami - sypialną dla prywatności i otwartą strefą dzienną dla wspólnego czasu. Projekt uwzględnia także dużą powierzchnię przechowywania na strychu, co jest praktycznym rozwiązaniem dla potrzeb magazynowych. Prosta konstrukcja domu z dwuspadowym dachem i drewnianymi stropami ułatwia budowę i obniża koszty. Dodatkowo, zastosowanie niedrogich materiałów elewacyjnych, pokrycia dachu oraz stolarki PVC optymalizuje koszty realizacji. Projekt ten jest także przyjazny dla środowiska dzięki energooszczędnym rozwiązaniom, takim jak standardowa wentylacja mechaniczna. Ostatecznie, dom jest nie tylko tani w budowie, ale również w eksploatacji, co czyni go doskonałym wyborem dla oszczędnych rodzin.'
                galleryImages={imagesSet1}
                m2='80,80 m²'


            />
        </div>
    );
}

export default FourWork;