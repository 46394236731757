import React from 'react';
import '../Components_SCSS/Partials/Partners.scss'
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import partner1 from '../Assets/Loga/Lasy.webp';
import partner2 from '../Assets/Loga/extradom.webp';
import partner3 from '../Assets/Loga/Blachy_wynik.webp';
import partner4 from '../Assets/Loga/cellfast.webp';
import partner5 from '../Assets/Loga/fakro_wynik.webp';
import partner6 from '../Assets/Loga/Florian_wynik.webp';
import partner7 from '../Assets/Loga/Oli.webp';
import partner8 from '../Assets/Loga/mrowka_wynik.webp';
import partner9 from '../Assets/Loga/marco_wynik.webp';
import partner10 from '../Assets/Loga/Koopmans.webp';


const PartnerCarousel = () => {
    const partnerLogos = [partner1, partner2, partner3, partner4, partner5, partner6, partner7, partner8, partner9, partner10];

    const carouselSettings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 1200,

        responsive: [
            {
                breakpoint: 1300,
                settings: {
                    slidesToShow: 2,
                },
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 1,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                },
            },
        ],
    };
    return (
        <div className="partners">
            <h2>Nasi partnerzy biznesowi</h2>
            <div className="partners__container container">
                <Slider {...carouselSettings}>
                    {partnerLogos.map((logo, index) => (
                        <div className='partners__container_logo' key={index}>
                            <img src={logo} alt={`Partner ${index + 1}`} />
                        </div>
                    ))}
                </Slider>
            </div>
        </div>
    );
}

export default PartnerCarousel;