import React, {useEffect, useRef, useState} from 'react';
import '../Components_SCSS/Partials/Navigation.scss'
import logo from '../Assets/xda-2.webp';
import {Link} from "react-router-dom";
import {FaBars, FaTimes} from 'react-icons/fa'; // Importuje ikony z biblioteki react-icons


const Navigation = () => {

    const navRef = useRef(null);
    const burgerRef = useRef(null);
    const burgerRefdiv = useRef(null);
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth > 768) {
                navRef.current.style.display = 'flex';
                burgerRef.current.style.display = 'none';
                burgerRefdiv.current.style.display = 'none';
                setIsMenuOpen(false);
            } else {
                burgerRef.current.style.display = 'block';
                burgerRefdiv.current.style.display = isMenuOpen ? 'block' : 'none';
                navRef.current.style.display = 'none';
            }
        };

        window.addEventListener('resize', handleResize);
        handleResize();

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [isMenuOpen]);

    const toggleMenu = () => {
        burgerRef.current.style.display = isMenuOpen ? 'none' : 'block';
        burgerRefdiv.current.style.display = isMenuOpen ? 'none' : 'block';
        setIsMenuOpen(!isMenuOpen);
    };


    return (
        <div className="navigation">
            <div className='nav_logo'>
                <Link to="/"><img src={logo} alt="Tartak Halama logo"/></Link>
            </div>
            <div className="menu-icon" onClick={toggleMenu} ref={burgerRef}>
                {isMenuOpen ? <FaTimes/> : <FaBars/>}
            </div>
            <div className="hamburger_menu" ref={burgerRefdiv}>

                <ul className='hamburger_menu_items'>
                    <li className='hamburger_menu_item'>
                        <Link to="/">O nas</Link>
                    </li>
                    <li className='hamburger_menu_item'>
                        <Link to="/projekty">Projekty</Link>
                    </li>
                    <li className='hamburger_menu_item'>
                        <Link to="/oferta">Oferta</Link>
                    </li>
                    <li className='hamburger_menu_item'>
                        <Link to="/nasze_prace">Wykonane prace</Link>
                    </li>
                    <li className='hamburger_menu_item'>
                        <Link to="/kontakt">Kontakt</Link>
                    </li>
                    <li className='hamburger_menu_item'>
                        <Link className='tomato' to="https://koszykstolarza.pl">Sklep</Link>
                    </li>
                </ul>
            </div>
            <nav className='nav_menu' ref={navRef}>
                <ul className='nav_menu_items'>
                    <li className='nav_menu_item'>
                        <Link to="/">O nas</Link>
                    </li>
                    <li className='nav_menu_item'>
                        <Link to="/projekty">Projekty</Link>
                    </li>
                    <li className='nav_menu_item'>
                        <Link to="/oferta">Oferta</Link>
                    </li>
                    <li className='nav_menu_item'>
                        <Link to="/nasze_prace">Wykonane prace</Link>
                    </li>
                    <li className='nav_menu_item'>
                        <Link to="/kontakt">Kontakt</Link>
                    </li>
                    <li className='nav_menu_item'>
                        <Link className='tomato' to="https://koszykstolarza.pl">Sklep</Link>
                    </li>
                </ul>
            </nav>


        </div>
    );

}

export default Navigation;