import React from 'react';
import Navigation from "../../Navigation";
import Component from "../Component/Component";
import img1 from "../../../Assets/nowe/20240125_164557.webp";

function Boazeria() {
    return (
        <div className="boazeria">
            <Navigation/>
            <Component
                img={img1}
                title="Boazeria"
                description="Odkryj uniwersalność i piękno naszej boazerii, dostępnej w wariantach drewna sosnowego, świerkowego i modrzewiowego.
Doskonale sprawdzi się zarówno w dekoracji wnętrz, jak i jako stylowa podbitka dachowa lub element elewacji zewnętrznej budynku.
Nasze boazerie są dostępne w standardowych wymiarach 2 x 10 cm, co gwarantuje estetyczny i harmonijny wygląd w każdym zastosowaniu.
Nasza boazeria jest frezowana na system pióro-wpust, który zapewnia szybki i łatwy montaż.
Jest to idealne rozwiązanie zarówno dla profesjonalistów, jak i dla amatorów majsterkowania, umożliwiając efektywną i bezproblemowy montaż.
"
                price="Domyślny wymiar: 2 x 10 krycia"
                link="Kontakt"
            />
        </div>
    );
}

export default Boazeria;