import React from 'react';
import Oferta from "./Components_JSX/Offer/Oferta";
import {BrowserRouter, Route, Routes,} from "react-router-dom";
import Main from "./Components_JSX/Main";
import Project from "./Components_JSX/Projects/Project";
import Our_Works from "./Components_JSX/Our_Works/Our_Works";
import FirstWork from "./Components_JSX/Our_Works/Works/JSX/First_Work";
import Contact from "./Components_JSX/Contact/Contact";
import DeskaTarasowa from "./Components_JSX/Offer/Components_JSX/Deska_Tarasowa";
import Polbal from "./Components_JSX/Offer/Components_JSX/Polbal";
import Boazeria from "./Components_JSX/Offer/Components_JSX/Boazeria";
import Wiezba from "./Components_JSX/Offer/Components_JSX/Wiezba";
import Tarcica from "./Components_JSX/Offer/Components_JSX/Tarcica";
import Deski from "./Components_JSX/Offer/Components_JSX/Deski";
import Lata from "./Components_JSX/Offer/Components_JSX/Lata";
import Krawedziaki from "./Components_JSX/Offer/Components_JSX/Krawedziaki";
import Zrebka from "./Components_JSX/Offer/Components_JSX/Zrebka";
import SecondWork from "./Components_JSX/Our_Works/Works/JSX/Second_Work";
import Threework from "./Components_JSX/Our_Works/Works/JSX/Three_work";
import Stages from "./Components_JSX/stages.jsx"
import FourWork from "./Components_JSX/Our_Works/Works/JSX/Four_Work";
import FiveWork from "./Components_JSX/Our_Works/Works/JSX/Five_Work";

function App() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path='/' element={<Main/>}/>
                <Route path='/projekty' element={<Project/>}/>
                <Route path='/oferta' element={<Oferta/>}/>
                <Route path='/nasze_prace' element={<Our_Works/>}/>
                <Route path='/kontakt' element={<Contact/>}/>
                <Route path='/nasze_prace/sosenka' element={<FirstWork/>}/>
                <Route path='/nasze_prace/l-39' element={<SecondWork/>}/>
                <Route path='/nasze_prace/GL-1439SZ' element={<Threework/>}/>
                <Route path='/nasze_prace/radosny1' element={<FourWork/>}/>
                <Route path='/nasze_prace/osobisty1' element={<FiveWork/>}/>
                <Route path='/stages' element={<Stages/>}/>
                <Route path='/oferta/deska_tarasowa' element={<DeskaTarasowa/>}/>
                <Route path='/oferta/polbal' element={<Polbal/>}/>
                <Route path='/oferta/boazeria' element={<Boazeria/>}/>
                <Route path='/oferta/wiezba' element={<Wiezba/>}/>
                <Route path='/oferta/tarcica' element={<Tarcica/>}/>
                <Route path='/oferta/deski' element={<Deski/>}/>
                <Route path='/oferta/lata_i_kontrlata' element={<Lata/>}/>
                <Route path='/oferta/krawedziaki' element={<Krawedziaki/>}/>
                <Route path='/oferta/zrebka' element={<Zrebka/>}/>


            </Routes>
        </BrowserRouter>
    );
}

export default App;
