import React from 'react';
import Navigation from "../../Navigation";
import Component from "../Component/Component";
import img1 from "../../../Assets/nowe/Krawędziaki.webp";

function Krawedziaki() {
    return (
        <div className="krawędziaki">
            <Navigation/>
            <Component
                img={img1}
                title="Krawędziaki"
                description="Zapraszamy do zapoznania się z naszą ofertą krawędziaków, niezbędnych elementów w projektach budowlanych i dekoracyjnych.
Nasze krawędziaki dostępne są w różnych wymiarach, w tym 10x10 cm, 12x12 cm, 5x10 cm, 6x12 cm, oraz w niestandardowych wymiarach na życzenie klienta.
Najczęściej wykonujemy je z drewna sosnowego, gwarantując trwałość i naturalny urok.
Doskonale sprawdzają się jako konstrukcja przy budowie altanek, tarasów, domków dla dzieci czy pergoli. Ich wszechstronność pozwala na kreatywne wykorzystanie ich w każdym projekcie.
Oferujemy możliwość impregnacji krawędziaków, zapewniając dodatkową ochronę przed czynnikami atmosferycznymi.
 Dodatkowo, na życzenie klienta, możemy dostarczyć strugane krawędziaki, które zachowują estetyczny wygląd i gładką powierzchnię."
                price="Wymiary: 10 x 10, 12 x 12, 5 x 10, 6 x 12 i wiele innych."
                link="Kontakt"
            />
        </div>);
}

export default Krawedziaki;