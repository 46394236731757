import React from 'react';
import '../Components_SCSS/Partials/Roadmap.scss';
import '../Components_SCSS/Partials/Main.scss';
import img1 from '../Assets/construction.webp'
import img2 from '../Assets/stopwatch.webp'
import img3 from '../Assets/paint.webp'
import img4 from '../Assets/folder-management.webp'
import img5 from '../Assets/check.webp'
import {Link} from "react-router-dom";


function Roadmap() {
    return (
        <div className='roadmap container'>
            <div className='roadmap_heading_content'>
                <h3 className='roadmap_heading_text'>Etapy rozpoczęcia budowy domu</h3>
                <p className='roadmap_heading_description'>Budowa domu z wykorzystaniem usług HAL-BUD Halama to
                    niezwykle zorganizowany proces, który łatwo
                    podzielić na 6 kluczowych etapów.</p>
            </div>
            <div className="roadmap_content container">
                <div className="roadmap_content_items">
                    <div className="roadmap_content_item">
                        <img src={img4} alt=""/>
                        <p className="roadmap_content_item_number">01</p>
                        <p className="roadmap_content_item_text">Przygotowanie i złożenie dokumentacji</p>
                    </div>
                </div>
                <div className="roadmap_content_items">
                    <div className="roadmap_content_item">
                        <img src={img2} alt=""/>
                        <p className="roadmap_content_item_number">02</p>
                        <p className="roadmap_content_item_text">Optymistyczne oczekiwanie na akceptację</p>
                    </div>
                </div>


                <div className="roadmap_content_items">
                    <div className="roadmap_content_item">
                        <img src={img1} alt=""/>
                        <p className="roadmap_content_item_number">03</p>
                        <p className="roadmap_content_item_text"> Budowa domu przez <br></br>HAL-BUD Halama</p>
                    </div>
                </div>


                <div className="roadmap_content_items">
                    <div className="roadmap_content_item">
                        <img src={img3} alt=""/>
                        <p className="roadmap_content_item_number">04</p>
                        <p className="roadmap_content_item_text">Wykonanie prac wykończeniowych</p>
                    </div>
                </div>


                <div className="roadmap_content_items">
                    <div className="roadmap_content_item">
                        <img src={img5} alt=""/>
                        <p className="roadmap_content_item_number">05</p>
                        <p className="roadmap_content_item_text">Finalne zakończenie i odbiór gotowego projektu</p>
                    </div>
                </div>

            </div>

            <Link to='/stages'>
                <button className='roadmap_button'>Czytaj więcej...</button>
            </Link>
        </div>
    )
        ;
}

export default Roadmap;