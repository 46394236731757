import React, {useEffect} from 'react';
import '../SCSS/First_Work.scss';
import Navigation from "../../../Navigation";
import Component_Work from "./Component_Work";
import img1 from "../../../../Assets/prywatny/20240131_110310.jpg";


const imagesSet1 = [
    {
        original: img1,
        thumbnail: img1,
        description: 'Opis zdjęcia 1',
    },

];

function FiveWork() {
    useEffect(() => {
        console.log('Efekt działa!');
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className="firstwork">
            <Navigation/>
            {/* eslint-disable-next-line react/jsx-pascal-case */}
            <Component_Work
                img={img1}
                title='Projekt prywatny'
                description='Ten nowoczesny projekt domu charakteryzuje się funkcjonalnym układem na dwóch kondygnacjach oraz eleganckim dachem dwuspadowym. Całkowita powierzchnia użytkowa budynku wynosi 180,5 m², co zapewnia przestronne i wygodne pomieszczenia dla całej rodziny. Zewnętrzna część domu zawiera przestronny taras o powierzchni 28 m², który stanowi idealne miejsce do wypoczynku na świeżym powietrzu i rozrywki. Taras doskonale komponuje się z całościowym wyglądem domu, tworząc harmonijną przestrzeń między wnętrzem a ogrodem.'
                galleryImages={imagesSet1}
                m2='180.5 m2'


            />
        </div>

    );
}

export default FiveWork;