import React, {useEffect, useMemo, useState} from 'react';
import '../../Components_SCSS/Partials/Heading.scss';
import FirstSlider from "./First_Slider";
import img1 from '../../Assets/1.webp';
import img2 from '../../Assets/2-2.webp';
import img3 from '../../Assets/3-1.webp';
import { useSwipeable } from 'react-swipeable';
import { FaArrowLeft } from "react-icons/fa";
import { FaArrowRight } from "react-icons/fa";

function Heading() {
    useEffect(() => {
        console.log('Efekt działa!');
        window.scrollTo(0, 0);
    }, []);
    const slides = useMemo(
        () => [
            {title: 'Twoja przestrzeń nasza pasja', description: 'Budujemy domy szkieletowe!', button: 'Projekty', link: '/projekty' , img: `url(${img1})`},
            {title: 'Tartak Halama', description: 'Twój Solidny Partner w Świecie Drewna!', button: 'Oferta', link: '/oferta' , img: `url(${img3})`},
            {title: 'Własny Sklep Budowlany', description: 'Materiały I Narzędzia Online', button: 'W Budowie!', link:'/' , img: `url(${img2})`},
        ],
        []
    );

    const [currentSlideIndex, setCurrentSlideIndex] = useState(0);
    const currentSlide = slides[currentSlideIndex];

    useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentSlideIndex((prevIndex) => (prevIndex + 1) % slides.length);
        }, 6000);

        return () => {
            clearInterval(intervalId);
        };
    }, [slides]);

    const goToPreviousSlide = () => {
        setCurrentSlideIndex((prevIndex) => prevIndex === 0 ? slides.length - 1 : prevIndex - 1);
    };

    const goToNextSlide = () => {
        setCurrentSlideIndex((prevIndex) => (prevIndex + 1) % slides.length);
    };
    const handlers = useSwipeable({
        onSwipedLeft: () => goToNextSlide(),
        onSwipedRight: () => goToPreviousSlide(),
        preventDefaultTouchmoveEvent: true,
        trackMouse: true
    });

    return (
        <div className="carousel-container" {...handlers}>
            <div className="carousel">

                <div className="heading">
                    <FirstSlider {...currentSlide} />
                </div>
                <div className="carousel-arrow_nav">
                <button className="carousel-arrow left" onClick={goToPreviousSlide}><FaArrowLeft color={"fff"} />Prev</button>
                <button className="carousel-arrow right" onClick={goToNextSlide}>Next<FaArrowRight color={"fff"} /></button>
                </div>
            </div>
        </div>
    );
}

export default Heading;