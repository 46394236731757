import React from 'react';
import '../Components_SCSS/Partials/Footer.scss';
import '../Components_SCSS/Partials/Main.scss'
import logo from '../Assets/xda-2.webp';
import {FaHouse} from "react-icons/fa6";
import {FaPhone} from "react-icons/fa6";
import {FaEnvelope} from "react-icons/fa6";
import {FaGlobe} from "react-icons/fa6";
import {MdOutlineAccessTimeFilled} from "react-icons/md";


function Footer() {
    return (
        <footer className="footer">
            <div className="footer__contact">
                <div className="footer__contact_content container">
                    <div className="footer__contact_logo">
                        <img src={logo} alt=""/>
                    </div>
                    <div className="footer__contact_about">
                        <h2>Tartak Halama</h2>
                        <p>Nasz tartak to firma rodzinna, a drewno to nasza pasja! Wspólnie działamy już ponad 10 lat.

                            Gwarantujemy drewno najwyższej jakości i indywidualne podejście do każdego klienta.</p>
                    </div>
                    <div className="footer__contact_address">
                        <div className="contact__address">
                            <FaHouse/>
                            <a target={"_blank"} href="https://maps.app.goo.gl/b3jGbKQkifEUxvCTA" rel="noreferrer"><p>Leśniewice 13,
                                09-500 Gostynin</p></a>
                        </div>
                        <div className="footer__contact_phone">
                            <div className="contact__phone">
                                <FaPhone/>
                                <a href="tel:601099456">601-099-456</a>
                            </div>

                        </div>
                        <div className="footer__contact_email">
                            <div className="contact__email">
                                <FaEnvelope/>
                                <a href="mailto:biuro@tartak-halama.pl">biuro@tartak-halama.pl</a>
                            </div>
                        </div>
                        <div className="footer__contact_website">
                            <div className="contact__website">
                                <FaGlobe/>
                                <a href="https://tartak-halama.pl/" target={"_blank"} rel="noreferrer">tartak-halama.pl</a>
                            </div>
                        </div>
                        <div className="footer__contact_time">
                            <div className="contact__time">
                                <MdOutlineAccessTimeFilled/>
                                <p>Pon. - Pt. od 7:00 do 16:00</p>
                            </div>
                        </div>
                    </div>
                    <div className="footer__contact_maps">
                        <iframe
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2435.6843703556897!2d19.460249576940743!3d52.37614294686997!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x471b8421ef58b6e1%3A0x14a6d8b50971600d!2sTARTAK%20HALAMA%20-%20DOMY%20Z%20DREWNA!5e0!3m2!1spl!2spl!4v1705937051317!5m2!1spl!2spl"
                            width="450" height="300" allowFullScreen="" loading="lazy"
                            referrerPolicy="no-referrer-when-downgrade"
                            title={"mapa"}>

                        </iframe>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;