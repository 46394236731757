import React, {useEffect} from 'react';
import '../SCSS/First_Work.scss';
import Navigation from "../../../Navigation";
import Component_Work from "./Component_Work";
import img1 from "../../../../Assets/sosenkaO/1 (1).webp";
import img2 from "../../../../Assets/sosenkaO/1 (2).webp";
import img3 from "../../../../Assets/sosenkaO/1 (3).webp";
import img4 from "../../../../Assets/sosenkaO/1 (4).webp";
import img5 from "../../../../Assets/sosenkaO/1 (5).webp";
import img6 from "../../../../Assets/sosenkaO/1 (6).webp";



const imagesSet1 = [
    {
        original: img1,
        thumbnail: img1,
        description: 'Szkieletowy dom nowoczesny',
    },
    {
        original: img2,
        thumbnail: img2,
        description: 'Blachodachówka tradycyjna',
    },
    {
        original: img3,
        thumbnail: img3,
        description: 'Dom szkieletowy nowy',
    },
    {
        original: img4,
        thumbnail: img4,
        description: 'Elegancka blachodachówka',
    },
    {
        original: img5,
        thumbnail: img5,
        description: 'Szkieletowa konstrukcja wewnątrz',
    },
    {
        original: img6,
        thumbnail: img6,
        description: 'Dom szkieletowy mały',
    },
];
function FirstWork() {
    useEffect(() => {
        console.log('Efekt działa!');
        window.scrollTo(0, 0);
    }, []);
    return (

        <div className="firstwork">
            <Navigation/>
            <Component_Work
                img={img2}
                title='Sosenka drewniana WAH1109'
                description='Projekt "Sosenka drewniana" to zmniejszona wersja popularnego projektu domu "Sosenka". Jest idealny dla wąskich działek, dzięki swoim wymiarom, które umożliwiają efektywne zagospodarowanie przestrzeni. Charakteryzuje się prostą, typową bryłą i kalenicą równoległą do drogi, co spełnia wymagania zabudowy. Projekt przewiduje dom wolnostojący z tanim i prostym w wykonaniu dachem dwuspadowym. Wejście jest od południa, co jest korzystne dla działek z wjazdem od tej strony. Dom parterowy z użytkowym poddaszem oferuje prywatność. Wnętrze zawiera przestronną część dzienną z kuchnią otwartą na salon z kominkiem, komfortową łazienkę i dwie sypialnie, idealne dla 2-4 osobowej rodziny, z opcją wykorzystania jako pokój gościnny lub gabinet. Taras i weranda sprzyjają relaksowi na świeżym powietrzu. Dom jest zaprojektowany w technologii szkieletowej z zewnętrznym wyglądem pasującym do stylu tradycyjnego.'
                galleryImages={imagesSet1}
                m2='51.25 m2'



            />
        </div>

    );
}

export default FirstWork;