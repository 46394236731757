import React from 'react';
import Navigation from "../../Navigation";
import Component from "../Component/Component";
import img1 from "../../../Assets/nowe/Tarcica.webp";

function Tarcica() {
    return (
        <div className="tarcica">
            <Navigation/>
            <Component
                img={img1}
                title="Tarcica"
                description="Zapewniamy bogaty wybór tarcicy w różnych rodzajach drewna, w tym sosnowym, świerkowym, modrzewiowym oraz dębowym, a także wiele innych gatunków.
Nasza tarcica jest doskonałym wyborem do różnorodnych zastosowań, spełniając różnorodne potrzeby naszych klientów.
Rozumiemy unikalne potrzeby każdego klienta i jesteśmy gotowi dostosować nasze usługi do Twoich specyficznych wymagań.
W przypadku większych zamówień oferujemy opcję przetarcia drewna dostarczonego przez klienta, co umożliwia idealne dopasowanie do Twojego projektu."
                price="Wymiary pod zamówienie klienta."
                link="Kontakt"
            />
        </div>
    );
}

export default Tarcica;