import React from 'react';
import Navigation from "../Navigation";
import './our_works.scss'
import '../../Components_SCSS/Partials/Main.scss';
import Components_Works from "./Components_Works";
import {Link} from "react-router-dom";
import Footer from "../Footer";
import sosenka from '../../Assets/Domki/sosenka_drewniana.webp'
import l39 from '../../Assets/Domki/l39.webp'
import aurora from "../../Assets/Domki/Aurora.jpg";
import radosny from "../../Assets/Domki/radosny.webp";
import prywatny from "../../Assets/prywatny/20240131_110233.jpg"

function Our_Works() {
    return (
        <>
            <div className='our_works'>
                <Navigation/>
                <h2>Zapraszamy do Obejrzenia Naszych Zrealizowanych Projektów</h2>
                <div className="our_works_content ">
                    <Link to={'/nasze_prace/radosny1'}>
                        <Components_Works
                            img1={radosny}
                            title='Radosny 2 WAW1141'
                            description='Projekt łączy ponadczasową estetykę z praktycznymi rozwiązaniami dla małych rodzin.
 Prosta konstrukcja z dwuspadowym dachem i niskokosztowe materiały sprawiają,
że jest tani w budowie i eksploatacji,
 a energooszczędne rozwiązania dodatkowo zmniejszają koszty utrzymania.'/>
                    </Link>

                    <Link to={'/nasze_prace/sosenka'}>
                        <Components_Works
                            img1={sosenka}
                            title='Sosenka drewniana WAH1109'
                            description='"Sosenka drewniana" to funkcjonalny, ekonomiczny dom dla 2-4 osób, idealny na wąską działkę. Zawiera otwartą strefę dzienną, dwie sypialnie i taras, w tradycyjnym stylu z nowoczesnym akcentem. Prosta realizacja dzięki technologii szkieletowej i dachowi dwuspadowemu.'/>
                    </Link>
                    <Link to={'/nasze_prace/l-39'}>
                        <Components_Works
                            img1={l39}
                            title='Projekt domu L-39'
                            description='"L-39" to kompaktowy projekt domu dla 4 osobowej rodziny, idealny na średniej wielkości działkę.
Oferuje parterową konstrukcję z użytkowym poddaszem, podzieloną na strefy dzienną i nocną.
Prosta bryła z dachem dwuspadowym i frontem od strony południowej sprawdzi się w różnych otoczeniach.
'/>
                    </Link>

                    <Link to={'/nasze_prace/osobisty1'}>
                        <Components_Works
                            img1={prywatny}
                            title='Projekt prywatny'
                            description='Projekt dwukondygnacyjnego domu z dachem dwuspadowym, o powierzchni użytkowej 180,5 m², zintegrowany z tarasem o powierzchni 28 m².
Architektura domu łączy w sobie klasyczne elementy z nowoczesnymi rozwiązaniami, tworząc przytulne, ale zarazem nowoczesne wnętrze.'/>
                    </Link>

                    <Link to={'/nasze_prace/GL-1439SZ'}>
                        <Components_Works
                            progress={'W trakcie realizacji'}
                            img1={aurora}
                            title='AURORA MAXI II SZ'
                            description='Projekt "Aurora Maxi II SZ" to efektywny dom szkieletowy z prostą bryłą i dwuspadowym dachem, oferujący otwartą strefę dzienną i cztery sypialnie. Idealny dla dużej rodziny, łączy atrakcyjny wygląd z ekonomiczną realizacją, pasując do różnorodnych środowisk.'/>
                    </Link>
                </div>

<Footer />
            </div>
        </>
    );
}

export default Our_Works;