import React from 'react';
import Navigation from "../../Navigation";
import Component from "../Component/Component";
import img1 from "../../../Assets/nowe/Więźba.webp";

function Wiezba() {
    return (
        <div className="wiezba_dachowa">
            <Navigation/>
            <Component
                img={img1}
                title="Więźba dachowa"
                description="Oferujemy profesjonalnie wykonaną więźbę dachową, produkowaną na indywidualne zamówienie. Nasze doświadczenie w branży pozwala nam dostarczać produkty spełniające najwyższe standardy jakości i trwałości.
Zaangażowanie w jakość zaczyna się już na etapie selekcji drewna. Każdy element więźby jest wybierany z myślą o maksymalnej wytrzymałości i estetyce, zapewniając niezawodność konstrukcji przez długie lata.
Na specjalne życzenie klienta, drewno może zostać zaimpregnowane, co zapewnia dodatkową ochronę przed szkodliwymi czynnikami zewnętrznymi.
Nasza impregnacja chroni drewno przed owadami, grzybami, pleśnią, nadmierną wilgocią, szkodliwym działaniem promieni słonecznych oraz zmianami temperatury, co znacząco wydłuża żywotność konstrukcji.
Każde zamówienie traktujemy indywidualnie, dostosowując się do wymagań i specyfikacji klienta. "
                price="Wymiary pod zamówienie klienta."
                link="Kontakt"
            />
        </div>
    );
}

export default Wiezba;