import React from 'react';
import Navigation from "../../Navigation";
import Component from "../Component/Component";
import img1 from "../../../Assets/nowe/Zrębka.webp";

function Zrebka(props) {
    return (
        <div className="zrebka">
            <Navigation/>
            <Component
                img={img1}
                title="Zrębka"
                description="Zrębka ogrodowa to mały element wykonany z drewna sosnowego o wymiarach około 5 cm na 3 cm. Jest to produkt popularny w ogrodnictwie i ma wiele zastosowań w tworzeniu pięknych ogrodów oraz aranżacji przestrzeni na świeżym powietrzu. Zrębki ogrodowe są wykorzystywane do różnych celów, takich jak:

Możesz użyć zrębek do wyłożenia ścieżek w ogrodzie. Tworzą one estetyczne i naturalne wykończenie, a jednocześnie zapobiegają wzrostowi chwastów.

Zrębki można rozsypać wokół roślin, tworząc atrakcyjne otoczenie i pomagając w utrzymaniu wilgoci w glebie.

Zrębki nadają się do ozdabiania skrzynek ogrodowych lub donic, dodając im naturalny urok."
                price="Sprzedaż w m3"
                link="Kontakt"
            />
        </div>
    );
}

export default Zrebka;