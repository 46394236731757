import React from 'react';
import './our_works.scss'
function Components_Works({img1,title, description,progress}) {
    return (
        <div className='our_works_content_box'>
            <div className="our_works_content_box_img">
                <img src={img1} alt=""/>

            </div>
            <div className="our_works_content_box_text">
                <span>{progress}</span>
                <h2>{title}</h2>
                <p>{description}</p>
            </div>
        </div>

    );
}

export default Components_Works;