import React, {useEffect} from 'react';
import '../../Components_SCSS/Partials/First_Slider.scss';
import Navigation from "../Navigation";
import '../../Components_SCSS/Partials/Navigation.scss'
import {SocialIcon} from 'react-social-icons'
import {Link} from "react-router-dom";


function FirstSlider({title, description, button, img, isVisible, link}) {

    useEffect(() => {
        console.log('Efekt działa!');
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className={`firstslider ${isVisible ? 'visible' : ''}`}>
            <div className='firstslider_box' style={{
                backgroundImage: img, transition:"ease-in 1.5s" ,backgroundSize: "cover", backgroundPosition: "center"
            }}>
                <nav><Navigation/></nav>
                <div className="firstslider_box_social_content">
                    <div className='firstslider_box_social'
                         style={{display: 'flex', alignItems: 'flex-start', flexDirection: 'column'}}>
                        <SocialIcon className='firstslider_social_icon' url="https://www.facebook.com/Tartak-Halama"
                                    bgColor="#5B4219" target="_blank" style={{margin: '1rem', padding: '.5rem',}}/>
                        <SocialIcon className='firstslider_social_icon' url="https://www.instagram.com/tartak_halama/"
                                    bgColor="#5B4219" target="_blank" style={{margin: '1rem', padding: '.5rem'}}/>
                        <SocialIcon className='firstslider_social_icon' url="https://maps.app.goo.gl/NdFhCs4dxDzuSvh28"
                                    target="_blank" bgColor="#5B4219" style={{margin: '1rem', padding: '.5rem'}}/>
                        <SocialIcon className='firstslider_social_icon' url="https://www.youtube.com/@tartakhalama7057"
                                    target="_blank" bgColor="#5B4219" style={{margin: '1rem', padding: '.5rem'}}/>
                    </div>
                    <div className='firstslider_box_content_text'>
                        <h2 className='firstslider_box_content_heading'>{title}</h2>
                        <p className='firstslider_box_content_description'>{description}</p>
                        <Link to={link} className='firstslider_box_content_button'>{button}</Link>
                    </div>
                </div>

            </div>

        </div>
    );
}

export default FirstSlider;