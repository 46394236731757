import React from 'react';
import '../../Components_SCSS/Partials/Oferta.scss';
import '../../Components_SCSS/Partials/Main.scss';
import {Link} from "react-router-dom";

function ComponentsOffer({title1, description1, img1, link1}) {
    return (

        <div className='oferta_produkt'>
            <img src={img1} alt=""/>
            <h4>{title1}</h4>
            <span></span>
            <p>{description1}</p>
            <Link to={link1}>Wchodzę</Link>
        </div>
    );
}

export default ComponentsOffer;