import React, {useEffect} from 'react';
import Navigation from "../../../Navigation";
import Component_Work from "./Component_Work";
import '../SCSS/First_Work.scss';
import img1 from "../../../../Assets/Domki/Aurora.jpg";

const imagesSet1 = [
    {
        original: img1,
        thumbnail: img1,
        description: 'Opis zdjęcia 1',
    },

];

function ThreeWork() {
    useEffect(() => {
        console.log('Efekt działa!');
        window.scrollTo(0, 0);
    }, []);
    return (

        <div className="firstwork">
            <Navigation/>
            {/* eslint-disable-next-line react/jsx-pascal-case */}
            <Component_Work
                img={img1}
                inprogress='W trakcie realizacji'
                title='AURORA MAXI II SZ'
                description='"Aurora Maxi II SZ" to funkcjonalny, szkieletowy dom rodzinny o prostym kształcie z wydzielonymi strefami dzienną i nocną. Otwarta przestrzeń łączy kuchnię, jadalnię i salon z dostępem do zadaszonego tarasu. Cztery sypialnie i przestronna łazienka tworzą komfortową strefę prywatną. Jego elegancka elewacja w bieli, graficie i drewnie podkreśla nowoczesny charakter, idealny zarówno do zabudowy miejskiej, jak i podmiejskiej.'
                galleryImages={imagesSet1}
                m2='132.60 m2'

            />
        </div>

    );
}

export default ThreeWork;