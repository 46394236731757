import React, {useRef, useState,useEffect } from 'react';
import Navigation from "../Navigation";
import './Contact.scss';
import {FaPhoneAlt} from "react-icons/fa";
import {MdEmail} from "react-icons/md";
import {FaMapMarker} from "react-icons/fa";
import emailjs from '@emailjs/browser';
import Alert from '@mui/material/Alert';
import Footer from "../Footer";


function Contact() {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phoneNumbers, setPhoneNumbers] = useState('');
    const [message, setMessage] = useState('');

    const [nameError, setNameError] = useState('');
    const [emailError, setEmailError] = useState('');
    const [phoneError, setPhoneError] = useState('');
    const [messageError, setMessageError] = useState('');
    const [showError, setShowError] = useState(false); // Nowy stan do kontrolowania pokazywania błędów
    const successTimeoutRef = useRef();
    const [displayErrors, setDisplayErrors] = useState({
        name: false,
        email: false,
        phoneNumbers: false,
        message: false
    });
    const [generalError, setGeneralError] = useState('');

    const [formReady, setFormReady] = useState(false);
    const [successAlert, setSuccessAlert] = useState(false);
    const [emailTouched, setEmailTouched] = useState(false);
    const [phoneTouched, setPhoneTouched] = useState(false);


    const form = useRef();

    const handleInputClick = (field) => {
        switch (field) {
            case 'name':
                setNameError('');
                break;
            case 'email':
                setEmailError('');
                break;
            case 'phone':
                setPhoneError('');
                break;
            case 'message':
                setMessageError('');
                break;
            default:
                break;
        }
    };

    const displayErrorTemporarily = () => {
        setSuccessAlert(true);
        successTimeoutRef.current = setTimeout(() => {
            setSuccessAlert(false);
        }, 4000); // Komunikat będzie widoczny przez 4 sekundy
    };

    useEffect(() => {
        // Czyszczenie timeoutu przy odmontowywaniu komponentu
        return () => {
            if (successTimeoutRef.current) {
                clearTimeout(successTimeoutRef.current);
            }
        };
    }, []);
    const handleEmailBlur = () => {
        setEmailTouched(true);

        // Sprawdzamy czy email jest pusty, nieprawidłowy, oraz czy długość jest w odpowiednim zakresie
        if (!email || !/@/.test(email) || email.length < 7 || email.length > 40) {
            setEmailError('Email musi być poprawny!');
            // Ustawiamy błąd, ale nie wyłączamy go automatycznie po 4 sekundach,
            // ponieważ użytkownik powinien mieć możliwość poprawienia go samodzielnie
            setDisplayErrors(prev => ({...prev, email: true}));
        } else {
            // Jeśli email jest prawidłowy, usuwamy błąd
            setEmailError('');
            setDisplayErrors(prev => ({...prev, email: false}));
        }
    };

    const handlePhoneBlur = () => {
        setPhoneTouched(true);
        if (phoneNumbers && !/\d{3}-\d{3}-\d{3}/.test(phoneNumbers)) {
            setPhoneError('Numer telefonu musi mieć 9 cyfr.');
            setDisplayErrors(prev => ({...prev, phoneNumbers: true}));
            setTimeout(() => setDisplayErrors(prev => ({...prev, phoneNumbers: false})), 4000);
        } else {
            setPhoneError('');
        }
    };

    const handleBlur = (field) => {
        switch (field) {
            case 'name':
                if (!name || name.length < 3 || name.length > 30)  {
                    setNameError('Imię i nazwisko są wymagane.');
                }
                break;
            case 'email':
                if ((!email || !/@/.test(email)) && (!email.length >= 9 && email.length <= 40)) {

                    setEmailError('Nieprawidłowy format adresu email.');
                }
                break;
            case 'phone':
                if (!phoneNumbers || !/\d{3}-\d{3}-\d{3}/.test(phoneNumbers)) {
                    setPhoneError('Numer telefonu musi mieć 9 cyfr.');
                }
                break;
            case 'message':
                if (!message || message.length < 10) {
                    setMessageError('Wiadomość musi mieć co najmniej 10 znaków.');
                }
                break;
            default:
                break;
        }
    };

    const formatPhoneNumber = (input) => {
        const cleaned = input.replace(/\D/g, '');
        if (cleaned.length === 0) return '';
        if (cleaned.length <= 3) return cleaned;
        if (cleaned.length <= 6) return `${cleaned.slice(0, 3)}-${cleaned.slice(3)}`;
        return `${cleaned.slice(0, 3)}-${cleaned.slice(3, 6)}-${cleaned.slice(6, 9)}`;
    };

    const handlePhoneChange = (event) => {
        const inputPhoneNumber = event.target.value;
        const formattedNumber = formatPhoneNumber(inputPhoneNumber);
        setPhoneNumbers(formattedNumber);
    };

    const validateForm = () => {
        let isValid = true;
        setShowError(false); // Resetujemy ogólny błąd na początku walidacji
        setGeneralError('');

        // Walidacja imienia i nazwiska
        if (!name || name.length < 3 || name.length > 30) {
            setNameError('Imię i nazwisko są wymagane i muszą mieć od 3 do 30 znaków.');
            isValid = false;
        } else {
            setNameError('');
        }

        // Walidacja emaila
        if (!email || !/@/.test(email) || email.length < 7 || email.length > 40) {
            setEmailError('Email jest wymagany i musi być poprawny i mieć od 7 do 40 znaków.');
            isValid = false;
        } else {
            setEmailError('');
        }

        // Walidacja numeru telefonu
        if (!phoneNumbers || !/\d{3}-\d{3}-\d{3}/.test(phoneNumbers)) {
            setPhoneError('Numer telefonu musi mieć 9 cyfr.');
            isValid = false;
        } else {
            setPhoneError('');
        }

        // Walidacja wiadomości
        if (!message || message.length < 10) {
            setMessageError('Wiadomość musi mieć co najmniej 10 znaków.');
            isValid = false;
        } else {
            setMessageError('');
        }

        if (!isValid) {
            setGeneralError('Formularz zawiera błędy!');
        }

        return isValid;
    };


    const sendEmail = (e) => {
        e.preventDefault();

        if (!validateForm()) {
            // Formularz jest niepoprawny, więc kończymy działanie funkcji
            return;
        }

        // Logika wysyłania formularza
        emailjs.sendForm('service_d7yjbrj', 'template_85tmpxv', form.current, 'ucrOAHWgChaPp6JUK')
            .then((result) => {
                displayErrorTemporarily();
                console.log(result.text);
                setSuccessAlert(true);
                setShowError(false); // Chowamy błędy po pomyślnym wysłaniu
                // Resetowanie formularza
                setName('');
                setEmail('');
                setPhoneNumbers('');
                setMessage('');
                // Resetowanie błędów
                setNameError('');
                setEmailError('');
                setPhoneError('');
                setMessageError('');
            }, (error) => {
                console.log(error.text);
                // Możemy tu ustawić ogólny komunikat o błędzie wysyłania
                setGeneralError('Nie udało się wysłać wiadomości. Proszę spróbować ponownie później.');
            });
    };

    return (
        <>
            <Navigation/>

            <div className="contact">
                <h1>Kontakt</h1>
                <div className="contact_content container">
                    <div className="contact_content_left">
                        <div className="left_info">
                            <h2>Dane kontaktowe</h2>
                            <div className="left_info_phone">
                                <FaPhoneAlt/>
                                <a href="tel:601099456">601-099-456</a>
                            </div>
                            <span></span>
                            <div className="left_info_email">
                                <MdEmail/>
                                <a href="mailto:biuro@tartak-halama.pl">biuro@tartak-halama.pl</a>
                            </div>
                            <span></span>
                            <div className="left_info_address">
                                <FaMapMarker/>
                                <a target={"_blank"} href="https://maps.app.goo.gl/b3jGbKQkifEUxvCTA" rel="noreferrer">Leśniewice 13, 09-500 Gostynin</a>
                            </div>
                            <span></span>
                        </div>

                        <div className="left_form">
                            <div className="contact_form_verfication">
                                {emailError && <Alert severity="error">{emailError}</Alert>}
                                {phoneError && <Alert severity="error">{phoneError}</Alert>}
                                {nameError && <Alert severity="error">{nameError}</Alert>}
                                {messageError && <Alert severity="error">{messageError}</Alert>}
                                {showError && <Alert severity="error">Formularz zawiera błędy!</Alert>}
                                {successAlert && <Alert severity="success">Wiadomość została wysłana pomyślnie!</Alert>}
                            </div>
                            <form ref={form} onSubmit={sendEmail}>
                                <div className="form_left">
                                    <label>Imię i Nazwisko</label>
                                    <input
                                        type="text"
                                        name="user_name"
                                        placeholder="Imię i Nazwisko"
                                        value={name}
                                        onChange={(e) => setName(e.target.value)}
                                        onClick={() => handleInputClick('name')}
                                        onBlur={() => handleBlur('name')}
                                    />
                                    <label>Email</label>
                                    <input
                                        type="email"
                                        name="user_email"
                                        value={email}
                                        onClick={() => handleInputClick('email')}
                                        onBlur={handleEmailBlur}
                                        placeholder='Wprowadź adres email'
                                        onChange={(e) => setEmail(e.target.value)}
                                    />
                                    <label>Numer kontaktowy</label>
                                    <input
                                        type="text"
                                        name="user_phone"
                                        placeholder="Numer kontaktowy"
                                        value={phoneNumbers}
                                        onChange={handlePhoneChange}
                                        onClick={() => handleInputClick('phone')}
                                        onBlur={handlePhoneBlur}
                                    />
                                </div>
                                <div className="form_right">
                                    <label>Wiadomość</label>
                                    <textarea
                                        name="message"
                                        onClick={() => handleInputClick('message')}
                                        onBlur={() => handleBlur('message')}
                                        placeholder='Wprowadź treść wiadomości'
                                        onChange={(e) => setMessage(e.target.value)}
                                    />
                                    <input className='form_right_button' type="submit" value="Wyślij"/>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div className="contact_content_right">
                        <h3>Mapa dojazdu</h3>
                        <iframe
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d38949.691817760526!2d19.404070488461365!3d52.400228639461936!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x471b8421ef58b6e1%3A0x14a6d8b50971600d!2sTARTAK%20HALAMA%20-%20DOMY%20Z%20DREWNA!5e0!3m2!1spl!2spl!4v1706107913106!5m2!1spl!2spl"
                            width="600" height="450" allowFullScreen="" loading="lazy"
                            referrerPolicy="no-referrer-when-downgrade"></iframe>
                    </div>
                </div>
            </div>
            <Footer/>
        </>
    );
}

export default Contact;