import React from 'react';
import Navigation from "../../Navigation";
import Component from "../Component/Component";
import img1 from "../../../Assets/nowe/Polbal.webp";
function Polbal() {
    return (
        <div className="polbal">
            <Navigation/>
            <Component
                img={img1}
                title="Deska Półbal"
                description="Poznaj nasz półbal – doskonałe rozwiązanie dla tych, którzy cenią naturalne piękno drewna, ale potrzebują bardziej praktycznego i łatwego w montażu produktu.
Nasz półbal, wykonany z wysokiej jakości drewna sosnowego, świerkowego lub modrzewiowego, to idealny sposób na dodanie charakteru i ciepła zarówno do wnętrz, jak i elewacji zewnętrznych.
Nasze półbale są starannie wykonane, aby naśladować wygląd prawdziwych balów drewnianych, co pozwala na stworzenie autentycznego i tradycyjnego wyglądu w każdej przestrzeni.
Dzięki zastosowaniu systemu pióro-wpust, montaż naszych półbalów jest szybki i prosty. "
                price="Domyślny wymiar: 2.8 x 14 krycia"
                link="Kontakt"
            />
        </div>
    );
}

export default Polbal;