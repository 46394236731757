import React from 'react';
import Navigation from "../../Navigation";
import Component from "../Component/Component";
import img1 from "../../../Assets/nowe/lata.jpg";

function Lata() {
    return (
        <div className="lata_kontrlata">
            <Navigation/>
            <Component
                img={img1}
                title="Łaty i kontrłaty"
                description="Oferujemy wysokiej jakości łaty i kontrłaty, które są kluczowymi elementami w konstrukcjach dachowych oraz innych projektach budowlanych.
Wykonane z solidnego drewna sosnowego, nasze łaty i kontrłaty gwarantują trwałość i niezawodność.
Łaty: Standardowo oferujemy wymiary 4x5 cm oraz 4x6 cm, ale jesteśmy w stanie dostosować wymiary do indywidualnych potrzeb klienta.
Kontrłaty: Standardowy wymiar to 2.5x5 cm, z możliwością dostosowania do specyficznych wymagań projektowych.
odobnie jak inne nasze produkty z drewna, łaty i kontrłaty są impregnowane, co zwiększa ich odporność na szkodliwe czynniki.
Impregnacja chroni drewno przed owadami, grzybami, pleśniami, nadmierną wilgocią, promieniami UV i zmianami temperatury, co jest szczególnie ważne w kontekście długotrwałego użytkowania na zewnątrz."
                price="Łata 4 x 5 lub 4 x 6 oraz kontrłata 2.5 x 5. "
                link="Kontakt"
            />
        </div>);
}

export default Lata;